<template>
  <div>
    <portal to="v-main">
      <goods-dialog v-model="showGoodsDialog" :id="idEditGoods" :object_="data" />
    </portal>
    <v-card-text style="height: 300px; overflow: auto" class="pa-0">
      <a-table-f-api
        ref="tableGoods"
        api="/mechti/construction-goods"
        :model="model"
        :searchable="false"
        :defaults="{
          filters: { object_id: id },
          sort: { key: 'id', order: 'DESC' },
          paramName: 'goods',
        }"
        @click="onClickGoodsRow($event)"
      >
        <template v-slot:top v-if="getAccess('object.goodsEdit', { access: data.object_access })">
          <v-fab-transition>
            <v-btn fab dark color="green" absolute bottom small style="bottom: 15px" left class="v-btn--example" @click="createNewGoods">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-fab-transition>
        </template>
      </a-table-f-api>
    </v-card-text>
  </div>
</template>
 
<script>
import { getAccess, popupMenu, genModel } from "@/components/mixings";
export default {
  mixins: [getAccess, popupMenu, genModel],
  components: {
    goodsDialog: () => import("@/views/dialogs/goodsDialog"),
  },
  props: {
    id: Number,
    data: Object,
  },
  data() {
    return {
      idEditGoods: 0,
      showGoodsDialog: false,
    };
  },
  created() {},
  watch: {
    showGoodsDialog() {
      if (!this.showGoodsDialog) {
        this.$refs.tableGoods.updateData();
      }
    },
  },
  computed: {
    model() {
      let model = [];
      let list = this.getModelList(this.$store.getters["config/get"].models.goods);
      list.forEach(l => {
        if (!["actions", "checked"].includes(l.name)) model.push(l);
      });
      if (this.$root.profile.role > 20)
        model.push({
          name: "doc_id",
          title: "Документ",
          type: "selectapi",
          itemField: { value: "id", text: "code_doc" },
          api: "accounting/doc/order_good",
          routeName: "ordersGoods_view",
          preText: "Заказ товаров №",
          isMenu: true,
        });
      return model;
    },
    model1() {
      let model = [];
      let list = this.$store.getters["config/get"].models.goods.list || [];
      list.forEach(l => {
        if (!["actions", "checked"].includes(l.name)) model.push(l);
      });

      return model;
    },
  },
  methods: {
    onClickGoodsRow(d) {
      if (this.$root.profile.role <= 20) return;
      if (d.field?.isMenu) {
        this.showPopupMenu(d.field.name, d.row, d.event, d);
        return;
      }
      this.showEditDialogGoodsFunc(d.row.id);
    },
    createNewGoods() {
      this.showEditDialogGoodsFunc(0);
    },
    showEditDialogGoodsFunc(id) {
      this.idEditGoods = id;
      this.showGoodsDialog = true;
    },
  },
};
</script>

<style lang="sass">
.v-slide-group1__prev--disabled
  display: none !important
.v-slide-group1__next--disabled
  display: none !important
</style>
